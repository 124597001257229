import React from 'react'
import ListHeader from './ListHeader'
import ListNote from './ListNote'
import { Card, CardRow } from './CardRow'
import Gauge from './Gauge'

interface Props {
  title: React.ReactNode
  note?: React.ReactNode
  min: number
  max: number
  current: number | null
}

export default React.memo(function SpeedCards({ title, note, min, max, current }: Props) {
  const isUnderspeed = (current ?? Infinity) < min
  const isOverspeed = (current ?? -Infinity) > max
  const hasNote = note !== undefined && note !== null

  return <>
    <ListHeader inset>{title}</ListHeader>
    <CardRow noTopMargin noBottomMargin={hasNote}>
      <Card popupHint="Минимальная скорость">
        <Gauge
          title=">"
          value={stringifySpeed(min)}
          subValue="км/ч"
          impression={isUnderspeed ? 'warning' : undefined}
        />
      </Card>
      <Card popupHint="Текущая скорость">
        <Gauge
          title="="
          value={stringifySpeed(current)}
          subValue="км/ч"
        />
      </Card>
      <Card popupHint="Максимальная скорость">
        <Gauge
          title="<"
          value={stringifySpeed(max)}
          subValue="км/ч"
          impression={isOverspeed ? 'warning' : undefined}
        />
      </Card>
    </CardRow>
    {hasNote && <ListNote inset>{note}</ListNote>}
  </>
})

function stringifySpeed(speed: number | null | undefined) {
  if (speed === null || speed === undefined) {
    return '–'
  }
  speed = Math.round(speed)
  if (speed < -999) {
    return '-∞'
  }
  if (speed > 999) {
    return '∞'
  }
  return String(speed)
}
