import React from 'react'
import { IonContent, IonItem, IonLabel, IonList, IonText } from '@ionic/react'
import { useDatastore } from '../datastore'
import {
  calculateDestinationTime,
  calculateTotalPause,
  CalculatorInput,
  parseTime,
  stringifyTime,
  stringifyTimeDifference,
  useCurrentTime,
} from '../utils'
import StatusBarUnderlay from './StatusBarUnderlay'
import ErrorMessage from './ErrorMessage'
import NumberPrompt from './NumberPrompt'
import ListHeader from './ListHeader'
import { ListItem } from './CardRow'
import Gauge from './Gauge'
import ListNote from './ListNote'

export default React.memo(function TabSector() {
  const [data, setData] = useDatastore()
  const startTime = parseTime(data.startTime)

  return <>
    <StatusBarUnderlay color="light" />
    <IonContent color="light" className="headerless-content">
      <ListHeader inset>Данные из дорожной книги</ListHeader>
      <IonList inset>
        <NumberInput
          value={data.allowedOutrunning}
          label="Допустимое опережение на ВКВ"
          hint="Как правило 10%"
          unit="%"
          onChange={value => setData({ allowedOutrunning: value })}
        />
        <NumberInput
          value={data.distance}
          label="Дистанция"
          hint="От начального КВ до конечного КВ"
          unit="км"
          unitSpace
          onChange={value => setData({ distance: value })}
        />
        <NumberInput
          value={data.averageSpeed}
          label="Средняя скорость"
          unit="км/ч"
          unitSpace
          onChange={value => setData({ averageSpeed: value })}
        />
        <NumberInput
          value={data.timeNorm}
          label="Норма времени"
          unit="мин."
          unitFull="минут"
          unitSpace
          onChange={value => setData({ timeNorm: value })}
        />
        <IonItem
          detail
          button
          onClick={event => event.currentTarget.querySelector('input')?.focus()}
        >
          <IonLabel>Время старта</IonLabel>
          <input
            slot="end"
            type="time"
            value={data.startTime ?? ''}
            step={60}
            onInput={event => setData({ startTime: event.currentTarget.value })}
            style={{ whiteSpace: 'nowrap' /* Багуется на iOS при, например, 20:09 */ }}
          />
        </IonItem>
      </IonList>

      {startTime === null ? (
        <ErrorMessage>Укажите время старта</ErrorMessage>
      ) : (
        <Calculations
          distance={data.distance}
          averageSpeed={data.averageSpeed}
          startTime={startTime}
          timeNorm={data.timeNorm}
        />
      )}
    </IonContent>
  </>
})

function NumberInput({
  value,
  label,
  hint,
  unit,
  unitFull = unit,
  unitSpace,
  onChange,
}: {
  value: number,
  label: string,
  hint?: string,
  unit: string,
  unitFull?: string,
  unitSpace?: boolean,
  onChange(value: number): void,
}) {
  const id = React.useId()

  return (
    <IonItem detail button id={id}>
      <IonLabel>{label}</IonLabel>
      <IonText slot="end" color="medium">{value}{unitSpace && ' '}{unit}</IonText>
      <NumberPrompt
        trigger={id}
        initialValue={value}
        label={`${label} (${unitFull})`}
        hint={hint}
        fractional
        onChange={onChange}
      />
    </IonItem>
  )
}

const Calculations = React.memo(function Calculations(props: Pick<
  CalculatorInput,
  'distance' | 'averageSpeed' | 'startTime' | 'timeNorm'
>) {
  const currentTime = useCurrentTime()
  const destinationTime = calculateDestinationTime(props)
  const destinationTimeLeft = destinationTime - currentTime
  const totalPause = calculateTotalPause(props)

  return <>
    <IonList inset>
      <ListItem>
        <Gauge
          title="Отметиться на КВ ровно в"
          value={stringifyTime(destinationTime * 60)}
          subValue={`(${stringifyTimeDifference(destinationTimeLeft * 60, true, 'через &', '& назад')})`}
          impression={destinationTimeLeft < -1 ? 'danger' : destinationTimeLeft < 0 ? 'warning' : undefined}
        />
      </ListItem>
      <IonItem>
        <IonLabel>Суммарное время ДС и нейтрализации</IonLabel>
        <IonText slot="end" color={totalPause < 0 ? 'danger' : 'medium'}>{totalPause} мин.</IonText>
      </IonItem>
    </IonList>
    <ListNote inset>
      Убедитесь, что совпадает с данными из дорожной книги
    </ListNote>
  </>
})
