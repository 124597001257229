import React from 'react'
import styles from './ListHeader.module.css'

interface Props {
  inset?: boolean
  children?: React.ReactNode
}

export default React.memo(function ListHeader({ inset, children }: Props) {
  const isMD = document.documentElement.classList.contains('md')

  return (
    <div className={`${styles.header} ion-color-${isMD ? 'secondary' : 'medium'} ion-padding-horizontal ${inset ? 'ion-margin-horizontal' : ''}`}>
      {children}
    </div>
  )
})
