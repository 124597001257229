import { useEffect, useState } from 'react'
import type { Data, RegularitySpeed } from './datastore'

/**
 * Количество цифр после запятой в отображении расстояний в дорожной книге
 */
export const distancePrecision = 2

/**
 * Максимальное допустимое опережение и опоздание на РД, выраженное в процентах
 */
export const regularityMaxDiffPercent = 1

interface NumberOptions {
  negative?: boolean
  fractional?: boolean
}

export function parseNumber(value: string, { negative, fractional }: NumberOptions = {}): number | null {
  value = value.replaceAll(',', '.')
  if (/\..*\./.test(value)) {
    return null
  }
  let number = parseFloat(value)
  if (isNaN(number)) {
    return null
  }
  if (!fractional) {
    number = Math.trunc(number)
  }
  if (!negative) {
    number = Math.abs(number)
  }
  return number
}

export function getNumberInputProps({ negative, fractional }: NumberOptions = {}) {
  return {
    type: 'text' as const,
    inputmode: negative ? 'text' as const : fractional ? 'decimal' as const : 'numeric' as const,
    pattern: `[0-9${fractional ? ',.' : ''}${negative ? '\\-' : ''}]`,
  }
}

/**
 * Парсит строку времени `hh:mm`. Возвращает количество минут с начала суток.
 */
export function parseTime(time: string | undefined): number | null {
  const match = /^\s*(\d+):(\d+)\s*$/.exec(time || '')
  if (!match) {
    return null
  }
  return parseInt(match[1]) * 60 + parseInt(match[2])
}

/**
 * Преобразует количество секунд с начала суток в абсолютное время (формат `h:mm` или `h:mm:ss`)
 */
export function stringifyTime(time: number, showSeconds?: boolean): string {
  const inDayTime = modulo(time, 60 * 60 * 24)
  const seconds = Math.floor(inDayTime) % 60
  const minutes = Math.floor(inDayTime / 60) % 60
  const hours = Math.floor(inDayTime / 60 / 60)
  const days = Math.floor(time / 60 / 60 / 24)

  const timeString = `${hours}:${String(minutes).padStart(2, '0')}${showSeconds ? `:${String(seconds).padStart(2, '0')}` : ''}`
  const dayString = days === 0 ? '' : ` (${days > 0 ? '+' : '−'}${Math.abs(days)}д)`
  return `${timeString}${dayString}`
}

export function stringifyDateTime(time: Date): string {
  return (
    `${time.getFullYear()}.${String(time.getMonth() + 1).padStart(2, '0')}.${String(time.getDate()).padStart(2, '0')}`
    + ` ${time.getHours()}:${String(time.getMinutes()).padStart(2, '0')}`
  )
}

/**
 * Преобразует количество секунд в строку разницы времени.
 * Секунды всегда выводятся двумя цифрами. Минуты двумя если есть часы. Часы всегда минимально необходимым количеством.
 */
export function stringifyTimeDifference(
  diffSeconds: number,
  showSeconds?: boolean,
  positiveTemplate = '+&',
  negativeTemplate = '−%',
): string {
  let timeString: string
  const positiveDiff = Math.abs(diffSeconds)

  if (positiveDiff === Infinity) {
    timeString = '∞'
  } else {
    const seconds = Math.floor(positiveDiff) % 60
    const minutes = Math.floor(positiveDiff / 60) % 60
    const hours = Math.floor(positiveDiff / 60 / 60)

    const secondsString = showSeconds ? `:${String(seconds).padStart(2, '0')}` : ''
    if (hours > 0) {
      timeString = `${hours}:${String(minutes).padStart(2, '0')}${secondsString}`
    } else {
      timeString = `${minutes}${secondsString}`
    }
  }

  return (diffSeconds < 0 ? negativeTemplate : positiveTemplate).replaceAll('&', timeString)
}

export interface CalculatorInput {
  /** % */
  allowedOutrunning: number,
  /** км/ч */
  averageSpeed: number,
  /** минуты с начала суток */
  startTime: number,
  /** минуты */
  timeNorm: number,
  /** минут */
  pausePassed: number,
  /** минуты с начала суток */
  currentTime: number,
  /** км */
  distance: number,
  /** км */
  odometer: number,
  /** минуты с начала суток */
  regularityStartTime: number
  /** Всегда упорядочены по возрастанию расстояния. Первая точка считается началом РД. */
  regularitySpeeds: RegularitySpeed[]
}

export function calculateRelativeIdealTime({
  averageSpeed,
  odometer,
}: Pick<CalculatorInput, 'averageSpeed' | 'odometer'>): number {
  // Сглаживаем погрешности JS при операциях с decimal, которые могут привести к заниженной минуте при Math.floor
  return round(odometer / (averageSpeed / 60), 60)
}

/**
 * Рассчитывает идеальное время прибытия на произвольную дистанцию (не обязательно конец секции).
 * Возвращает количество минут с начала суток.
 */
function calculateIdealTime(input: Pick<
  CalculatorInput,
  'averageSpeed' | 'startTime' | 'pausePassed' | 'odometer'
>): number {
  // Сглаживаем погрешности JS при операциях с decimal, которые могут привести к заниженной минуте при Math.floor
  return input.startTime + input.pausePassed + calculateRelativeIdealTime(input)
}

/**
 * Рассчитывает время, в которое нужно прибыть на КВ. Возвращает количество минут с начала суток (всегда целое число).
 */
export function calculateDestinationTime({ startTime, timeNorm }: Pick<CalculatorInput, 'startTime' | 'timeNorm'>): number {
  return startTime + timeNorm
}

/**
 * Возвращает сумму времени ДС и нейтрализации в минутах (всегда целое число)
 */
export function calculateTotalPause(input: Pick<CalculatorInput, 'averageSpeed' | 'distance' | 'timeNorm'>) {
  // Время, рассчитанное через умножение указанной в дорожной книге дистанции на среднюю скорость, не всегда даёт ровную
  // минуту, но организаторы подразумевают именно ровную минуту.
  const idealTime = Math.floor(calculateRelativeIdealTime({ ...input, odometer: input.distance }))
  return input.timeNorm - idealTime
}

/**
 * Рассчитывает минимальное время, в которое можно прибыть на ВКВ, если бы он был прямо сейчас. Возвращает количество
 * минут с начала суток (всегда целое число). Параметр `distance` должен содержать показания одометра.
 */
export function calculateSuddenControlMinTime(input: Pick<
  CalculatorInput,
  'allowedOutrunning' | 'averageSpeed' | 'startTime' | 'pausePassed' | 'odometer'
>) {
  // https://autokorr.ru/wp-content/uploads/2022/02/r3k_rules22.pdf 5.9.3.3
  // Время движения от начала дорожного сектора до пункта ВКВ, час:мин | Льгота, час:мин
  // от 0:00 до 0:10 (включительно) | 0:01
  // от 0:11 до 0:20 (включительно) | 0:02
  // от 0:21 до 0:30 (включительно) | 0:03
  // и т.д.
  const relativeIdealTime = calculateRelativeIdealTime(input)
  const relativeMinTime = Math.floor(relativeIdealTime)
  const relativeAllowedMinTime = relativeMinTime - Math.ceil(input.allowedOutrunning / 100 * relativeMinTime)
  return input.startTime + input.pausePassed + relativeAllowedMinTime
}

/**
 * Обратная функция для calculateSuddenControlMinTime. Возвращает максимально расстояние, которое можно преодолеть к
 * началу указанной минуты, не опережая ВКВ.
 *
 * Параметр currentTime должен содержать время, которое нужно проверить.
 */
export function calculateMaxAllowedOdometer({
  startTime,
  currentTime,
  allowedOutrunning,
  averageSpeed,
  pausePassed,
}: Pick<
  CalculatorInput,
  'allowedOutrunning' | 'averageSpeed' | 'startTime' | 'currentTime' | 'pausePassed'
>) {
  const timeWithOutrunning = Math.floor(currentTime - (startTime + pausePassed))
  const timeWithoutOutrunning = timeWithOutrunning + Math.ceil(timeWithOutrunning / (100 / allowedOutrunning - 1))
  return timeWithoutOutrunning * (averageSpeed / 60)
}

/**
 * Рассчитывает максимальную скорость так, чтобы не опередить время на любом ВКВ
 */
export function calculateMaxSpeed(input: Pick<
  CalculatorInput,
  'allowedOutrunning' | 'averageSpeed' | 'startTime' | 'currentTime' | 'pausePassed' | 'odometer'
>) {
  const nextTime = Math.floor(input.currentTime) + 1
  const maxOdometer = calculateMaxAllowedOdometer({ ...input, currentTime: nextTime })

  if (input.odometer >= maxOdometer) {
    return 0 // Значит, что идём с опережением ВКВ
  }

  return (maxOdometer - input.odometer) / ((nextTime - input.currentTime) / 60)
}

/**
 * Рассчитывает минимальную скорость так, чтобы успеть отметиться на КВ
 */
export function calculateMinSpeed(input: Pick<
  CalculatorInput,
  'averageSpeed' | 'startTime' | 'currentTime' | 'pausePassed' | 'odometer' | 'distance' | 'timeNorm'
>) {
  // Время, рассчитанное через умножение указанной в дорожной книге дистанции на среднюю скорость, не всегда даёт ровную
  // минуту, но организаторы подразумевают именно ровную минуту. Поэтому пересчитываем среднюю скорость так, чтобы
  // расчётное время указывало точно на начало минуты прибытия на КВ.
  const roadRelativeTime = input.timeNorm - calculateTotalPause(input)
  const averageSpeed = input.distance / (roadRelativeTime / 60)
  // Не прибавляем сюда предстоящие ДС и нейтрализацию, чтобы не уменьшить скорость слишком сильно.
  const finishTime = calculateIdealTime({ ...input, averageSpeed, odometer: input.distance })
  const timeLeft = finishTime - input.currentTime
  const distanceLeft = input.distance - input.odometer

  if (distanceLeft <= 0) {
    return 0
  }
  if (timeLeft <= 0) {
    return Infinity
  }
  return distanceLeft / (timeLeft / 60)
}

/**
 * Рассчитывает количество минут с начала РД, в которое нужно быть на указанном расстоянии по правилам РД.
 * Массив regularitySpeeds должен быть не пустым.
 */
export function calculateRelativeRegularityTime({ regularitySpeeds, odometer }: Pick<
  CalculatorInput,
  'regularitySpeeds' | 'odometer'
>) {
  if (regularitySpeeds.length === 0) {
    return NaN
  }

  let time = 0

  for (let i = 0; i < regularitySpeeds.length; i++) {
    const { distance, speed } = regularitySpeeds[i]
    const nextDistance = i + 1 < regularitySpeeds.length ? regularitySpeeds[i + 1].distance : Infinity
    if (odometer <= nextDistance) {
      return time + (odometer - distance) / (speed / 60)
    }
    time += (nextDistance - distance) / (speed / 60)
  }

  // Недостижимая точка алгоритма
  return NaN
}

/**
 * Получает среднюю скорость (в км/ч), указанная для текущего отрезка РД.
 * Массив regularitySpeeds должен быть не пустым.
 */
export function getCurrentRegularityAverageSpeed({ regularitySpeeds, odometer }: Pick<
  CalculatorInput,
  'regularitySpeeds' | 'odometer'
>) {
  if (regularitySpeeds.length === 0) {
    return NaN
  }

  for (let i = 0; i < regularitySpeeds.length; ++i) {
    const nextDistance = i + 1 < regularitySpeeds.length ? regularitySpeeds[i + 1].distance : Infinity
    if (odometer <= nextDistance) {
      return regularitySpeeds[i].speed
    }
  }

  // Недостижимая точка алгоритма
  return NaN
}

/**
 * Рассчитывает отставание (+) или опережение (-) в РД. Массив regularitySpeeds должен быть не пустым.
 */
export function calculateRegularityDelay(input: Pick<
  CalculatorInput,
  'regularitySpeeds' | 'odometer' | 'regularityStartTime' | 'currentTime'
>) {
  const idealTime = calculateRelativeRegularityTime(input)
  const realTime = input.currentTime - input.regularityStartTime
  return {
    minutes: realTime - idealTime,
    percent: (realTime - idealTime) / Math.abs(idealTime) * 100,
  }
}

/**
 * Рассчитывает минимальную и максимальную скорость во время прохождения РД.
 * Массив regularitySpeeds должен быть не пустым.
 */
export function calculateRegularitySpeed(input: Pick<
  CalculatorInput,
  'regularitySpeeds' | 'odometer' | 'regularityStartTime' | 'currentTime'
>) {
  const { percent: delayPercent } = calculateRegularityDelay(input)
  const averageSpeed = getCurrentRegularityAverageSpeed(input)
  const exertion = 0.15 * Math.min(1, Math.abs(delayPercent) / regularityMaxDiffPercent)

  if (delayPercent > 0) {
    return {
      min: averageSpeed * (1 + exertion),
      max: Infinity,
    }
  } else {
    return {
      min: 0,
      max: averageSpeed * (1 - exertion),
    }
  }
}

type OdometerState = Pick<Data, 'odometer' | 'odometerGpsOffset' | 'odometerCarOffset'>

export function calculateOdometers({ odometer, odometerGpsOffset, odometerCarOffset }: OdometerState) {
  return {
    roadmap: odometer,
    gps: odometer + odometerGpsOffset,
    car: odometer + odometerCarOffset,
  }
}

export function changeOdometers(
  oldState: Readonly<OdometerState>,
  changedType: keyof ReturnType<typeof calculateOdometers>,
  changedValue: number,
  doChangeAll: boolean,
): OdometerState {
  let { odometer, odometerGpsOffset, odometerCarOffset } = oldState
  let diff: number

  switch (changedType) {
    case 'roadmap':
      diff = changedValue - odometer
      odometer += diff
      if (!doChangeAll) {
        odometerGpsOffset -= diff
        odometerCarOffset -= diff
      }
      break
    case 'gps':
      diff = changedValue - (odometer + odometerGpsOffset)
      if (doChangeAll) {
        odometer += diff
      } else {
        odometerGpsOffset += diff
      }
      break
    case 'car':
      diff = changedValue - (odometer + odometerCarOffset)
      if (doChangeAll) {
        odometer += diff
      } else {
        odometerCarOffset += diff
      }
      break
    default:
      throw new TypeError(`Unexpected changedType value ${JSON.stringify(changedType)}`)
  }

  return { odometer, odometerGpsOffset, odometerCarOffset }
}

function getCurrentDaySeconds() {
  const localUnixMs = Date.now() - new Date().getTimezoneOffset() * 60000
  return Math.floor(localUnixMs / 1000) % 86400
}

/**
 * Возвращает количество минут с начала суток
 */
export function useCurrentTime() {
  const [currentDaySeconds, setCurrentDaySeconds] = useState(getCurrentDaySeconds)

  useEffect(() => {
    const intervalId = setInterval(() => setCurrentDaySeconds(getCurrentDaySeconds()), 100)
    return () => clearInterval(intervalId)
  }, [setCurrentDaySeconds])

  return currentDaySeconds / 60
}

// https://stackoverflow.com/a/17323608/1118709
function modulo(n: number, m: number): number {
  return ((n % m) + m) % m;
}

/**
 * @param value Число, которое надо округлить
 * @param precision Действует обратным образом. Например, 100 округляет до 2 знаков после запятой.
 * @param method В какую сторону округлять
 */
export function round(value: number, precision: number, method = Math.round): number {
  return method(value * precision) / precision
}

export function throttle(time: number, action: () => void): () => void {
  let timeoutId: ReturnType<typeof setTimeout> | null = null

  return () => {
    if (timeoutId === null) {
      setTimeout(() => {
        timeoutId = null
        action()
      }, time)
    }
  }
}

/**
 * Преобразует скорость из метров в секунду в километры в час
 */
export function mpsToKmph(metersPerSecond: number) {
  return metersPerSecond / 1000 * 60 * 60
}

export function waitUserInteraction(timeoutMs: number) {
  return new Promise<void>(resolve => {
    const target = window
    const events = ['click', 'mousedown', 'touchstart']
    const listenOptions = { once: true, capture: true, passive: true }

    const handler = () => {
      for (const event of events) {
        target.removeEventListener(event, handler, listenOptions)
      }
      resolve()
    }

    for (const event of events) {
      target.addEventListener(event, handler, listenOptions)
    }

    setTimeout(handler, timeoutMs)
  })
}

export function minMax(min: number, value: number, max: number): number {
  return Math.max(min, Math.min(value, max))
}

export function getSign(value: number) {
  if (value > 0) {
    return '+'
  }
  if (value < 0) {
    return '−'
  }
  return ''
}

/**
 * Добавляет новую запись в список средних скоростей на РД так, чтобы список оставался упорядоченным.
 * Не изменяет аргументы по ссылке.
 */
export function addRegularitySpeed(speeds: readonly RegularitySpeed[], newSpeed: RegularitySpeed): RegularitySpeed[] {
  let index = 0

  while (index < speeds.length && newSpeed.distance > speeds[index].distance) {
    index++
  }

  return [...speeds.slice(0, index), newSpeed, ...speeds.slice(index)]
}

/**
 * Заменяет запись в список средних скоростей на РД так, чтобы список оставался упорядоченным.
 * Не изменяет аргументы по ссылке.
 */
export function replaceRegularitySpeed(
  speeds: readonly RegularitySpeed[],
  replaceIndex: number,
  newSpeed: RegularitySpeed,
): RegularitySpeed[] {
  return addRegularitySpeed(removeRegularitySpeed(speeds, replaceIndex), newSpeed)
}

/**
 * Удаляет запись из списка средних скоростей на РД. Не изменяет аргументы по ссылке.
 */
export function removeRegularitySpeed(speeds: readonly RegularitySpeed[], removeIndex: number): RegularitySpeed[] {
  return [...speeds.slice(0, removeIndex), ...speeds.slice(removeIndex + 1)]
}
