import React from 'react'
import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonText } from '@ionic/react'
import * as icons from 'ionicons/icons'
import { useDatastore } from '../datastore'
import {
  calculateDestinationTime,
  calculateMaxSpeed,
  calculateMinSpeed,
  calculateSuddenControlMinTime,
  calculateTotalPause,
  CalculatorInput,
  minMax,
  parseTime,
  stringifyTimeDifference,
  useCurrentTime,
} from '../utils'
import StatusBarUnderlay from './StatusBarUnderlay'
import ErrorMessage from './ErrorMessage'
import NumberPrompt from './NumberPrompt'
import ListNote from './ListNote'
import { Card, CardRow } from './CardRow'
import Gauge, { ProgressGauge } from './Gauge'
import Odometer from './Odometer'
import SpeedCards from './SpeedCards'

export default React.memo(function TabSpeed() {
  const [data, setData] = useDatastore()
  const startTime = parseTime(data.startTime)
  const currentTime = useCurrentTime()

  return <>
    <StatusBarUnderlay color="light" />
    <IonContent color="light" className="headerless-content">
      <PauseInput
        distance={data.distance}
        averageSpeed={data.averageSpeed}
        timeNorm={data.timeNorm}
        pausePassed={data.pausePassed}
        setData={setData}
      />
      <Odometer
        odometer={data.odometer}
        odometerGpsOffset={data.odometerGpsOffset}
        odometerCarOffset={data.odometerCarOffset}
        gpsOutdated={data.gpsOutdated}
        setData={setData}
      />

      {startTime === null ? (
        <ErrorMessage>Укажите время старта во вкладке «Дорожный сектор»</ErrorMessage>
      ) : <>
        <Progress
          odometer={data.odometer}
          distance={data.distance}
          currentTime={currentTime}
          startTime={startTime}
          timeNorm={data.timeNorm}
        />
        <TimeControl
          currentTime={currentTime}
          allowedOutrunning={data.allowedOutrunning}
          averageSpeed={data.averageSpeed}
          startTime={startTime}
          pausePassed={data.pausePassed}
          odometer={data.odometer}
        />
        <SpeedControl
          currentTime={currentTime}
          allowedOutrunning={data.allowedOutrunning}
          averageSpeed={data.averageSpeed}
          startTime={startTime}
          pausePassed={data.pausePassed}
          odometer={data.odometer}
          distance={data.distance}
          timeNorm={data.timeNorm}
          currentSpeed={data.gpsPosition?.speed ?? null}
        />
      </>}
    </IonContent>
  </>
})

const PauseInput = React.memo(function PauseInput({
  distance,
  averageSpeed,
  timeNorm,
  pausePassed,
  setData,
}: Pick<
  CalculatorInput,
  'distance' | 'averageSpeed' | 'timeNorm' | 'pausePassed'
> & {
  setData: ReturnType<typeof useDatastore>[1]
}) {
  const label = 'Потрачено минут на ДС и нейтрализацию'
  const pauseTotal = calculateTotalPause({ distance, averageSpeed, timeNorm })
  const handleChange = React.useCallback((pausePassed: number) => setData({ pausePassed }), [setData])

  return <>
    <IonList inset>
      <IonItem>
        <IonLabel>{label}</IonLabel>
        <div slot="end" style={{ display: 'flex', alignItems: 'center' }}>
          <IonIcon
            icon={icons.removeCircle}
            size="large"
            color={pausePassed <= 0 ? 'medium' : 'primary'}
            onClick={event => {
              event.preventDefault()
              setData({ pausePassed: pausePassed - 1 })
            }}
          />
          <IonText
            id="pausePassed"
            color={pausePassed < 0 || pausePassed > pauseTotal ? 'danger' : ''}
            className="monospace-digits"
            style={{ padding: '0.25em', fontWeight: 'bold' }}
          >
            {pausePassed}
          </IonText>
          <IonIcon
            icon={icons.addCircle}
            size="large"
            color={pausePassed >= pauseTotal ? 'medium' : 'primary'}
            onClick={event => {
              event.preventDefault()
              setData({ pausePassed: pausePassed + 1 })
            }}
          />
        </div>
      </IonItem>
    </IonList>
    <ListNote inset>
      Должно стать {pauseTotal} в конце сектора
    </ListNote>
    <NumberPrompt
      trigger="pausePassed"
      initialValue={pausePassed}
      label={label}
      onChange={handleChange}
    />
  </>
})

const Progress = React.memo(function Progress(props: Pick<
  CalculatorInput,
  'odometer' | 'distance' | 'startTime' | 'currentTime' | 'timeNorm'
>) {
  const distanceProgress = minMax(0, props.odometer / props.distance, 1)
  const destinationTime = calculateDestinationTime(props)
  const timeProgress = minMax(0, (props.currentTime - props.startTime) / (destinationTime - props.startTime), 1)
  const isDistanceComplete = props.odometer >= props.distance - 0.1 // Чуть меньше расстояние на погрешность одометра
  const isTimeComplete = props.currentTime >= destinationTime
  const isTimeOverdue = props.currentTime >= destinationTime + 1
  const distanceImpression = isDistanceComplete ? 'success' : undefined
  const timeImpression = isTimeOverdue ? 'danger' : isTimeComplete ? 'warning' : undefined

  return (
    <CardRow>
      <Card popupHint={
        isDistanceComplete
          ? `Вы прибыли на КВ, ${
            isTimeComplete
              ? 'скорее отметьтесь у судьи'
              : `подождите ${stringifyTimeDifference((destinationTime - props.currentTime) * 60, true, '&')} и отметьтесь у судьи`
          }`
          : undefined
      }>
        <ProgressGauge
          title="Дистанция"
          titleNowrap
          value={distanceProgress}
          impression={distanceImpression}
        />
      </Card>
      <Card popupHint={isTimeComplete ? 'Время пришло, скорее отметьтесь на КВ' : undefined}>
        <ProgressGauge
          title="Время"
          titleNowrap
          value={timeProgress}
          impression={timeImpression}
        />
      </Card>
    </CardRow>
  )
})

const TimeControl = React.memo(function TimeControl(props: Pick<
  CalculatorInput,
  'currentTime' | 'allowedOutrunning' | 'averageSpeed' | 'startTime' | 'pausePassed' | 'odometer'
>) {
  const minAllowedTime = calculateSuddenControlMinTime(props)
  const suddenTimeControlDelay = props.currentTime - minAllowedTime

  return (
    <CardRow>
      <Card popupHint="Если бы внезапный контроль времени был прямо здесь. Можно опаздывать, но нельзя опережать.">
        <Gauge
          title="Внезапный контроль времени"
          titleNowrap
          value={stringifyTimeDifference(Math.abs(Math.round(suddenTimeControlDelay * 60)), true, '&')}
          subValue={suddenTimeControlDelay > 0 ? 'Запоздание' : 'Опережение'}
          impression={suddenTimeControlDelay >= 1 ? 'success' : suddenTimeControlDelay >= 0 ? 'warning' : 'danger'}
        />
      </Card>
    </CardRow>
  )
})

const SpeedControl = React.memo(function SpeedControl(props: Pick<
  CalculatorInput,
  'currentTime' | 'allowedOutrunning' | 'averageSpeed' | 'startTime' | 'pausePassed' | 'odometer' | 'distance' | 'timeNorm'
> & {
  currentSpeed: number | null
}) {
  const maxSpeed = calculateMaxSpeed(props)
  const minSpeed = Math.min(calculateMinSpeed(props), maxSpeed)

  return (
    <SpeedCards
      title="Скорость"
      note="Старайтесь держать ближе к максимальной, если не нужно поддерживать регулярность движения"
      min={minSpeed}
      max={maxSpeed}
      current={props.currentSpeed}
    />
  )
})
