import React from 'react'
import { PredefinedColors } from '@ionic/core'
import { IonProgressBar } from '@ionic/react'
import styles from './Gauge.module.css'

interface Props {
  title?: React.ReactNode
  titleNowrap?: boolean
  value?: React.ReactNode
  subValue?: React.ReactNode
  impression?: PredefinedColors
}

const Gauge = React.memo(function Gauge({
  title = null,
  titleNowrap,
  value = null,
  subValue = null,
  impression = 'dark',
}: Props) {
  const colorClass = `ion-color-${impression}`

  return <>
    <div className={`${styles.main} ${colorClass}`}>
      {title !== null && <div className={`${styles.title} ${titleNowrap ? styles.nowrap : ''}`}>{title}</div>}
      {value !== null && <div className={`${styles.value} monospace-digits`}>{value}</div>}
    </div>
    {subValue !== null && <div className={`${styles.subValue} ${colorClass} monospace-digits`}>{subValue}</div>}
  </>
})

export default Gauge

interface ProgressGaugeProps extends Omit<Props, 'value' | 'subValue'> {
  value: number
}

export const ProgressGauge = React.memo(function ProgressGauge({ value, impression, ...props }: ProgressGaugeProps) {
  return (
    <Gauge
      {...props}
      value={`${Math.floor(value * 100)}%`}
      subValue={<IonProgressBar value={value} color={impression} />}
      impression={impression}
    />
  )
})
