import React from 'react'
import { PredefinedColors } from '@ionic/core'
import styles from './ListNote.module.css'

interface Props {
  color?: PredefinedColors
  inset?: boolean
  children?: React.ReactNode
}

export default React.memo(function ListNote({ color = 'medium', inset, children }: Props) {
  return (
    <div className={`${styles.note} ion-color-${color} ion-padding-horizontal ${inset ? 'ion-margin-horizontal' : ''}`}>
      {children}
    </div>
  )
})
