import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { setupIonicReact } from '@ionic/react'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import App from './view/App';
import ErrorBoundary from './view/ErrorBoundary'

import '@ionic/react/css/core.css'
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'
import '@ionic/react/css/padding.css'
// import '@ionic/react/css/float-elements.css'
// import '@ionic/react/css/text-alignment.css'
// import '@ionic/react/css/text-transformation.css'
// import '@ionic/react/css/flex-utils.css'
// import '@ionic/react/css/display.css'
import './index.css'

setupIonicReact()

const root = ReactDOM.createRoot(document.querySelector('#root')!);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
