import { useEffect, useState } from 'react'
import EventDispatcher, { CancelWatcher, Watcher } from './eventDispatcher'
import { waitUserInteraction } from './utils'

export type State = 'unsupported' | 'inactive' | 'active'

/**
 * Делает так, чтобы экран никогда не гас (и GPS продолжал работать).
 *
 * Safari не даст этой системе сработать, пока пользователь повзаимодействует с элементом на странице (скролл не считается).
 */
class KeepAwake {
  #debug: boolean
  #state: State
  #stateChangeEventDispatcher = new EventDispatcher<State>()

  constructor(debug = false) {
    this.#debug = debug

    if (!navigator.wakeLock) {
      this.#log('navigator.wakeLock unavailable')
      this.#state = 'unsupported'
      return
    }

    this.#state = 'inactive'
    this.#keepAwake()
  }

  getState(): State {
    return this.#state
  }

  onStateChange(watcher: Watcher<State>): CancelWatcher {
    return this.#stateChangeEventDispatcher.watch(watcher)
  }

  #setState(state: State) {
    if (state !== this.#state) {
      this.#state = state
      this.#stateChangeEventDispatcher.dispatch(state)
    }
  }

  async #keepAwake() {
    let wakeLock: WakeLockSentinel | null = null
    let wasError = false

    while (true) {
      if (wasError) {
        this.#log('waiting for a user interaction or the timeout')
        await waitUserInteraction(5_000)
      }

      try {
        wakeLock = await navigator.wakeLock.request('screen')
      } catch (error) {
        wasError = true
        this.#log('lock request error', error)
        continue
      }

      wasError = false
      this.#log('lock acquired')
      this.#setState('active')

      await new Promise(r => wakeLock?.addEventListener('release', r)) // eslint-disable-line no-loop-func
      wakeLock = null
      this.#log('navigator.wakeLock released by itself')
      this.#setState('inactive')
    }
  }

  #log(...args: unknown[]) {
    if (this.#debug) {
      console.log('[keepAwake.ts]', ...args)
    }
  }
}

const keepAwake = new KeepAwake(false)

export default keepAwake

export function useKeepAwakeState(): State {
  const [state, setState] = useState(() => keepAwake.getState())
  useEffect(() => keepAwake.onStateChange(setState), [setState])
  return state
}
