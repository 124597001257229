import React from 'react'
import { getNumberInputProps, parseNumber } from '../utils'
import { IonAlert } from '@ionic/react'

interface Props {
  initialValue: number
  label: string
  hint?: string | undefined
  trigger?: string
  fractional?: boolean
  negative?: boolean
  onInput?(value: number | null): void
  onChange(value: number): void
}

export default React.memo(React.forwardRef(function NumberPrompt({
  initialValue,
  label,
  hint,
  trigger,
  fractional,
  negative,
  onInput,
  onChange,
}: Props, ref: React.ForwardedRef<HTMLIonAlertElement>) {
  const [isIncorrect, setIncorrect] = React.useState(false)
  // oninput ломает нативную обработку формы, плюс нативная обработка багованная, поэтому обрабатываем вручную
  const valueRef = React.useRef<number | null>(null)

  const handleInput = (event: InputEvent) => {
    valueRef.current = parseNumber((event.currentTarget as HTMLInputElement).value, { negative, fractional })
    onInput?.(valueRef.current)
  }

  const handleSubmit = () => {
    if (valueRef.current === null) {
      setIncorrect(true)
      return false
    }
    onChange(valueRef.current)
    return true
  }

  return <IonAlert
    trigger={trigger}
    header={label}
    subHeader={hint}
    message={isIncorrect ? 'Введите число' : undefined}
    backdropDismiss={false} // Часто когда хочешь нажать «Сохранить», жмёшь по ошибке на фон и отменяешь ввод
    inputs={[{
      attributes: {
        ...getNumberInputProps({ negative, fractional }),
        oninput: handleInput,
      },
    }]}
    buttons={[
      { text: 'Сохранить', role: 'confirm', handler: handleSubmit },
      { text: 'Отмена', role: 'cancel' },
    ]}
    onWillPresent={event => {
      setIncorrect(false)
      valueRef.current = initialValue
      event.target.querySelector('input')!.value = String(initialValue)
    }}
    onDidPresent={event => event.target.querySelector('input')?.focus()}
    ref={ref}
  />
}))
