import React from 'react'
import { IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react'
import { IonReactHashRouter } from '@ionic/react-router'
import { Route, Redirect } from 'react-router'
import * as icons from 'ionicons/icons'
import TabSector from './TabSector'
import TabSpeed from './TabSpeed'
import TabRegularity from './TabRegularity'
import TabConfig from './TabConfig'

export default React.memo(function App() {
  return (
    <IonApp>
      {/* @ts-ignore */}
      <IonReactHashRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Redirect exact path="/" to="/sector" />
            <Route path="/sector" exact component={TabSector} />
            <Route path="/speed" exact component={TabSpeed} />
            <Route path="/regularity" exact component={TabRegularity} />
            <Route path="/config" exact component={TabConfig} />
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="sector" href="/sector">
              <IonIcon icon={icons.map} />
              <IonLabel>Дорожный сектор</IonLabel>
            </IonTabButton>
            <IonTabButton tab="speed" href="/speed">
              <IonIcon icon={icons.speedometer} />
              <IonLabel>Контроль скорости</IonLabel>
            </IonTabButton>
            <IonTabButton tab="regularity" href="/regularity">
              <IonIcon icon={icons.playForward} />
              <IonLabel>Регулярность движения</IonLabel>
            </IonTabButton>
            <IonTabButton tab="config" href="/config">
              <IonIcon icon={icons.cog} />
              <IonLabel>Параметры</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactHashRouter>
    </IonApp>
  )
})
