export type Watcher<T> = (event: T) => void
export type CancelWatcher = () => void

export default class EventDispatcher<T> {
  #watchers: Watcher<T>[] = []

  public watch(watcher: Watcher<T>): CancelWatcher {
    this.#watchers.push(watcher)
    return () => {
      const index = this.#watchers.indexOf(watcher)
      if (index !== -1) {
        this.#watchers.splice(index, 1)
      }
    }
  }

  public dispatch(event: T): void {
    for (const watcher of this.#watchers) {
      try {
        watcher(event)
      } catch (error) {
        console.error(error)
      }
    }
  }
}
