import React from 'react'
import { IonIcon, IonText } from '@ionic/react'
import * as icons from 'ionicons/icons'
import styles from './ErrorMessage.module.css'

interface Props {
  children?: React.ReactNode
}

export default React.memo(function ErrorMessage({ children }: Props) {
  return (
    <IonText className={`${styles.message} ion-margin-horizontal ion-padding-horizontal`} color="danger">
      <IonIcon icon={icons.warning} className={styles.icon} />
      <div>{children}</div>
    </IonText>
  )
})
