import React from 'react';
import { IonButton } from '@ionic/react'
import datastore from '../datastore'
import styles from './ErrorBoundary.module.css';

interface Props {
  children?: React.ReactNode
}

interface State {
  error: Error | undefined;
}

export default class ErrorBoundary extends React.PureComponent<Props, State> {
  public state: State = {
    error: undefined
  }

  public static getDerivedStateFromError(error: Error): Partial<State> {
    return { error };
  }

  public render() {
    const { children = null } = this.props;
    const { error } = this.state;

    if (!error) {
      return children;
    }

    return (
      <div className={styles.root}>
        <h2>Неожиданная ошибка</h2>
        <IonButton className={styles.button} onClick={this.onDismiss}>
          Перезапустить
        </IonButton>
        <IonButton className={styles.button} onClick={this.onResetAndDismiss}>
          Сбросить состояние и перезапустить
        </IonButton>
      </div>
    );
  }

  private onDismiss = () => {
    this.setState({ error: undefined })
  }

  private onResetAndDismiss = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Все поля обнулятся. Вы уверены?')) {
      datastore.reset()
      this.setState({ error: undefined })
    }
  }
}
