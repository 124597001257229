import React, { HTMLAttributes } from 'react'
import styles from './CardRow.module.css'
import { IonContent, IonItem, IonLabel, IonList, IonPopover } from '@ionic/react'

interface CardRowProps extends HTMLAttributes<HTMLDivElement> {
  noTopMargin?: boolean
  noBottomMargin?: boolean
  children?: React.ReactNode
}

export function CardRow({ noTopMargin, noBottomMargin, className = '', ...props }: CardRowProps) {
  const classes = [styles.row, className]
  if (noTopMargin) {
    classes.push(styles.noTopMargin)
  }
  if (noBottomMargin) {
    classes.push(styles.noBottomMargin)
  }
  return <div className={classes.join(' ')} {...props} />
}

interface CardProps extends HTMLAttributes<HTMLIonListElement> {
  noPadding?: boolean
  popupHint?: React.ReactNode
}

export function Card({ noPadding, popupHint = null, children, className = '', ...props }: CardProps) {
  const id = React.useId()

  return <>
    <IonList inset className={`${styles.card} ${className}`} {...props} id={id}>
      {noPadding ? children : <ListItem>{children}</ListItem>}
    </IonList>
    {popupHint !== null && (
      <IonPopover trigger={id}>
        <IonContent class="ion-padding">{popupHint}</IonContent>
      </IonPopover>
    )}
  </>
}

interface ListItemProps {
  children?: React.ReactNode
}

/**
 * Положите в <IonList>, чтобы создать равномерные отступы вокруг
 */
export function ListItem({ children }: ListItemProps) {
  return <IonItem><IonLabel className={styles.label}>{children}</IonLabel></IonItem>
}
