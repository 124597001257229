import React from 'react'
import { IonContent, IonItem, IonLabel, IonList, IonText } from '@ionic/react'
import * as icons from 'ionicons/icons'
import { Data, RegularitySpeed, useDatastore } from '../datastore'
import {
  addRegularitySpeed,
  calculateRegularityDelay,
  calculateRegularitySpeed,
  CalculatorInput,
  distancePrecision,
  parseTime,
  regularityMaxDiffPercent,
  removeRegularitySpeed,
  replaceRegularitySpeed,
  round,
  stringifyTimeDifference,
  useCurrentTime,
} from '../utils'
import StatusBarUnderlay from './StatusBarUnderlay'
import ListHeader from './ListHeader'
import ListNote from './ListNote'
import ModalRegularitySpeed from './ModalRegularitySpeed'
import Odometer from './Odometer'
import { Card, CardRow } from './CardRow'
import Gauge from './Gauge'
import ErrorMessage from './ErrorMessage'
import SpeedCards from './SpeedCards'

export default React.memo(function TabRegularity() {
  const [data, setData] = useDatastore()
  const regularityStartTime = parseTime(data.regularityStartTime)
  const currentTime = useCurrentTime()
  const contentRef = React.useRef<HTMLIonContentElement>(null)
  useScrollToBottom(contentRef)

  return <>
    <StatusBarUnderlay color="light" />
    <IonContent color="light" className="headerless-content" ref={contentRef}>
      <ListNote inset>
        Регулярность движения (РД) — один из видов дополнительных соревнований (ДС).
        Используйте эту вкладку только во время прохождения РД.
      </ListNote>
      <AverageSpeeds
        regularitySpeeds={data.regularitySpeeds}
        odometer={data.odometer}
        setData={setData}
      />
      <StartTime
        regularityStartTime={data.regularityStartTime}
        setData={setData}
      />
      <Odometer
        odometer={data.odometer}
        odometerGpsOffset={data.odometerGpsOffset}
        odometerCarOffset={data.odometerCarOffset}
        gpsOutdated={data.gpsOutdated}
        setData={setData}
      />

      {regularityStartTime === null || data.regularitySpeeds.length === 0 ? (
        <ErrorMessage>
          {regularityStartTime === null && 'Укажите время начала РД. '}
          {data.regularitySpeeds.length === 0 && 'Добавьте хотя бы одну среднюю скорость. '}
        </ErrorMessage>
      ) : <>
        <TimeControl
          regularityStartTime={regularityStartTime}
          regularitySpeeds={data.regularitySpeeds}
          odometer={data.odometer}
          currentTime={currentTime}
        />
        <SpeedControl
          regularityStartTime={regularityStartTime}
          regularitySpeeds={data.regularitySpeeds}
          odometer={data.odometer}
          currentTime={currentTime}
          currentSpeed={data.gpsPosition?.speed ?? null}
        />
      </>}
    </IonContent>
  </>
})

const AverageSpeeds = React.memo(function AverageSpeeds({ regularitySpeeds, odometer, setData }: Pick<
  Data,
  'regularitySpeeds' | 'odometer'
> & {
  setData: ReturnType<typeof useDatastore>[1]
}) {
  const modalRef = React.useRef<HTMLIonModalElement>(null)
  const [editingIndex, setEditingIndex] = React.useState<number | null>(null) // null означает, что добавляется новая отметка

  const editItem = React.useCallback(
    (index: number | null = null) => {
      setEditingIndex(index)
      modalRef.current?.present()
    },
    [setEditingIndex, modalRef],
  )

  const handleSubmit = React.useCallback(
    (item: RegularitySpeed) => {
      setData({
        regularitySpeeds: editingIndex === null
          ? addRegularitySpeed(regularitySpeeds, item)
          : replaceRegularitySpeed(regularitySpeeds, editingIndex, item),
      })
      modalRef.current?.dismiss()
    },
    [setData, editingIndex, modalRef, regularitySpeeds],
  )

  const handleDelete = React.useMemo(
    () => editingIndex === null ? undefined : () => {
      setData({
        regularitySpeeds: removeRegularitySpeed(regularitySpeeds, editingIndex),
      })
      modalRef.current?.dismiss()
    },
    [setData, editingIndex, modalRef, regularitySpeeds],
  )

  const handleDismiss = React.useCallback(() => modalRef.current?.dismiss(), [modalRef])

  // AverageSpeedsList сделан отдельным компонентом, чтобы постоянное изменение odometer не приводило к рендеру списка
  return <>
    <AverageSpeedsList
      regularitySpeeds={regularitySpeeds}
      onAdd={editItem}
      onEdit={editItem}
    />
    <ModalRegularitySpeed
      ref={modalRef}
      title={editingIndex === null ? 'Новая отметка' : `Отметка №${editingIndex + 1}`}
      initialDistance={regularitySpeeds[editingIndex ?? -1]?.distance ?? round(odometer, 10 ** distancePrecision)}
      initialSpeed={regularitySpeeds[editingIndex ?? -1]?.speed ?? null}
      onDismiss={handleDismiss}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
    />
  </>
})

const AverageSpeedsList = React.memo(function AverageSpeeds({ regularitySpeeds, onAdd, onEdit }: Pick<
  Data,
  'regularitySpeeds'
> & {
  onAdd(): void
  onEdit(index: number): void
}) {
  const distanceCounts: Record<number, number> = {}
  for (const { distance } of regularitySpeeds) {
    distanceCounts[distance] = (distanceCounts[distance] ?? 0) + 1
  }

  return <>
    <ListHeader inset>Средняя скорость</ListHeader>
    <IonList inset>
      {regularitySpeeds.map(({ distance, speed }, index) => (
        <IonItem key={index} button detail onClick={() => onEdit(index)}>
          <IonLabel color={distanceCounts[distance] > 1 ? 'danger' : undefined}>{distance} км</IonLabel>
          <IonText slot="end" color={speed > 0 ? 'medium' : 'danger'}>{speed} км/ч</IonText>
        </IonItem>
      ))}
      <IonItem button detail detailIcon={icons.add} onClick={() => onAdd()}>
        <IonLabel color="medium">Добавить</IonLabel>
      </IonItem>
    </IonList>
    <ListNote inset>
      Первая строка — начало РД
    </ListNote>
  </>
})

const StartTime = React.memo(function StartTime({ regularityStartTime, setData }: Pick<Data, 'regularityStartTime'> & {
  setData: ReturnType<typeof useDatastore>[1]
}) {
  return (
    <IonList inset>
      <IonItem
        detail
        button
        onClick={event => event.currentTarget.querySelector('input')?.focus()}
      >
        <IonLabel>Время начала РД</IonLabel>
        <input
          slot="end"
          type="time"
          value={regularityStartTime ?? ''}
          step={60}
          onInput={event => setData({ regularityStartTime: event.currentTarget.value })}
          style={{ whiteSpace: 'nowrap' /* Багуется на iOS при, например, 20:09 */ }}
        />
      </IonItem>
    </IonList>
  )
})

const TimeControl = React.memo(function TimeControl(props: Pick<
  CalculatorInput,
  'regularityStartTime' | 'regularitySpeeds' | 'odometer' | 'currentTime'
>) {
  const delay = calculateRegularityDelay(props)

  return (
    <CardRow>
      <Card>
        <Gauge
          title="Регулярность движения"
          value={stringifyTimeDifference(Math.abs(Math.round(delay.minutes * 60)), true, '&')}
          subValue={delay.minutes > 0 ? 'Запоздание' : 'Опережение'}
          impression={
            Math.abs(delay.percent) <= regularityMaxDiffPercent
              ? 'success'
              : Math.abs(delay.percent) <= regularityMaxDiffPercent * 3
                ? 'warning'
                : 'danger'
          }
        />
      </Card>
    </CardRow>
  )
})

const SpeedControl = React.memo(function SpeedControl(props: Pick<
  CalculatorInput,
  'regularityStartTime' | 'regularitySpeeds' | 'odometer' | 'currentTime'
> & {
  currentSpeed: number | null
}) {
  const speed = calculateRegularitySpeed(props)

  return (
    <SpeedCards
      title="Скорость"
      min={speed.min}
      max={speed.max}
      current={props.currentSpeed}
    />
  )
})

function useScrollToBottom(elementRef: React.RefObject<HTMLIonContentElement>) {
  React.useLayoutEffect(() => {
    elementRef.current?.scrollToBottom(0)
  }, [elementRef])
}
