import React from 'react'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from '@ionic/react'
import { getNumberInputProps, parseNumber } from '../utils'
import { RegularitySpeed } from '../datastore'
import ListNote from './ListNote'

interface Props {
  title: React.ReactNode
  initialDistance: number | null
  initialSpeed: number | null
  trigger?: string
  onDismiss(): void
  onSubmit(values: Pick<RegularitySpeed, 'distance' | 'speed'>): void
  onDelete?(): void
}

export default React.memo(React.forwardRef(function ModalRegularitySpeed({
  title,
  initialDistance,
  initialSpeed,
  trigger,
  onDismiss,
  onSubmit,
  onDelete,
}: Props, ref: React.ForwardedRef<HTMLIonModalElement>) {
  const distanceInput = useInput(initialDistance)
  const speedInput = useInput(initialSpeed)
  const [presentAlert] = useIonAlert()
  const numberOptions = { fractional: true, negative: false }

  const handleSubmit = async () => {
    const distance = parseNumber(await distanceInput.getValue(), numberOptions)
    const speed = parseNumber(await speedInput.getValue(), numberOptions)

    if (distance !== null && speed !== null) {
      onSubmit({ distance, speed })
      return
    }

    let message = ''
    if (distance === null) {
      message += 'Расстояние от старта должно быть числом (количество километров). '
    }
    if (speed === null) {
      message += 'Средняя скорость должна быть числом (километров в час). '
    }
    presentAlert(message)
  }

  return (
    <IonModal
      ref={ref}
      trigger={trigger}
      backdropDismiss={false}
      onWillPresent={() => {
        distanceInput.actualizeValue()
        speedInput.actualizeValue()
      }}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="secondary">
            <IonButton role="cancel" onClick={onDismiss}>Отмена</IonButton>
          </IonButtons>
          {onDelete && (
            <IonButtons slot="primary">
              <IonButton color="danger" onClick={onDelete}>Удалить</IonButton>
            </IonButtons>
          )}
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        <IonList inset>
          <IonItem>
            <IonInput
              label="Расстояние от старта"
              placeholder="км"
              clearInput
              autoFocus
              ref={distanceInput.ref}
              {...getNumberInputProps(numberOptions)}
            />
          </IonItem>
          <IonItem>
            <IonInput
              label="Средняя скорость"
              placeholder="км/ч"
              clearInput
              ref={speedInput.ref}
              {...getNumberInputProps(numberOptions)}
            />
          </IonItem>
        </IonList>
        <ListNote inset>
          Средняя скорость начинается от этого расстояния и действует до следующей отметки
        </ListNote>
        <IonButton expand="block" className="ion-margin" onClick={handleSubmit}>Сохранить</IonButton>
      </IonContent>
    </IonModal>
  )
}))

function useInput(initialValue: string | number | null) {
  const ref = React.useRef<HTMLIonInputElement>(null)

  return {
    ref,
    async getValue() {
      const nativeInput = await ref.current?.getInputElement()
      return nativeInput ? nativeInput.value : ''
    },
    async actualizeValue() {
      const nativeInput = await ref.current?.getInputElement()
      if (nativeInput) {
        nativeInput.value = initialValue === null ? '' : String(initialValue)
        nativeInput.dispatchEvent(new Event('input')) // Чтобы появилась кнопка очистки
      }
    },
  }
}
