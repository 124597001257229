import getDistance from 'geolib/es/getDistance'
import EventDispatcher, { CancelWatcher, Watcher } from './eventDispatcher'
import appDatastore, { GpsPosition } from './datastore'
import { mpsToKmph } from './utils'
import { useEffect, useState } from 'react'

export type State = 'unknown' | 'unsupported' | 'userDenied' | 'error' | 'ok'

const maxBreakTime = 5 // Секунд
const maxBreakDistance = 50 // Метров

class GPS {
  #state: State = 'unknown'
  #stateChangeEventDispatcher = new EventDispatcher<State>()
  #datastore: typeof appDatastore

  constructor(datastore: typeof appDatastore) {
    this.#datastore = datastore

    if (!navigator.geolocation) {
      this.#state = 'unsupported'
      return
    }

    navigator.geolocation.watchPosition(
      this.#handleGeolocationUpdate.bind(this),
      this.#handleGeolocationError.bind(this),
      {
        enableHighAccuracy: true,
      },
    )
  }

  getState(): State {
    return this.#state
  }

  onStateChange(watcher: Watcher<State>): CancelWatcher {
    return this.#stateChangeEventDispatcher.watch(watcher)
  }

  #setState(state: State) {
    if (state !== this.#state) {
      this.#state = state
      this.#stateChangeEventDispatcher.dispatch(state)
    }
  }

  #handleGeolocationUpdate({ coords, timestamp }: GeolocationPosition) {
    this.#setState('ok')
    let { gpsPosition: oldPosition, gpsOutdated, odometer } = this.#datastore.get()
    const newPosition: GpsPosition = {
      latitude: coords.latitude,
      longitude: coords.longitude,
      altitude: coords.altitude,
      accuracy: coords.accuracy,
      speed: coords.speed === null ? null : mpsToKmph(coords.speed),
      timestamp,
    }

    if (oldPosition) {
      // geolib игнорирует перепады высот, но это не должно быть проблемой в Уссурийске, потому что он плоский
      const distanceMeters = getDistance(oldPosition, newPosition, 2 ** -52)
      if (distanceMeters > newPosition.accuracy * 2) {
        odometer += distanceMeters / 1000
      } else {
        // Игнорируем перемещение, если она в пределах погрешности (чтобы не накручивать одометр стоя на месте)
        newPosition.latitude = oldPosition.latitude
        newPosition.longitude = oldPosition.longitude
        newPosition.altitude = oldPosition.altitude
      }

      if (newPosition.timestamp - oldPosition.timestamp > maxBreakTime * 1000 && distanceMeters > maxBreakDistance) {
        gpsOutdated = true
      }
    }

    this.#datastore.change({
      odometer,
      gpsPosition: newPosition,
      gpsOutdated,
    })
  }

  #handleGeolocationError(error: GeolocationPositionError) {
    if (error.code === GeolocationPositionError.PERMISSION_DENIED) {
      this.#setState('userDenied')
    } else {
      console.error('Geolocation error', error.code, error.message)
      this.#setState('error')
    }
  }
}

const gps = new GPS(appDatastore)

export default gps

export function useGpsState(): State {
  const [state, setState] = useState(() => gps.getState())
  useEffect(() => gps.onStateChange(setState), [setState])
  return state
}
