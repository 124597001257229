import React from 'react'
import { IonActionSheet, IonContent, IonItem, IonLabel, IonList, IonText, isPlatform } from '@ionic/react'
import { useDatastore } from '../datastore'
import { useGpsState } from '../gps'
import { useKeepAwakeState } from '../keepAwake'
import { stringifyDateTime, stringifyTime, useCurrentTime } from '../utils'
import * as buildInfo from '../buildInfo'
import StatusBarUnderlay from './StatusBarUnderlay'
import ListHeader from './ListHeader'

export default React.memo(function TabConfig() {
  const gpsState = useGpsState()
  const keepAwakeState = useKeepAwakeState()
  const [data] = useDatastore()

  return <>
    <StatusBarUnderlay color="light" />
    <IonContent color="light" className="headerless-content">
      <IonList inset>
        <IonItem detail button id="whatItIs">
          <IonLabel>Что здесь происходит</IonLabel>
          <IonActionSheet
            trigger="whatItIs"
            header="О ралли 3 категории"
            buttons={[
              { text: 'Основы 1 (YouTube)', handler: () => openLink('https://youtube.com/watch?v=4zd6wKRQgms&t=211') },
              { text: 'Основы 2 (YouTube)', handler: () => openLink('https://youtube.com/watch?v=rr3qK4f4BrY&t=33') },
              { text: 'Регулярность движения (YouTube)', handler: () => openLink('https://youtube.com/watch?v=wLYp0AY6-FY&t=374') },
              { text: 'Правила ралли от РАФ (PDF)', handler: () => openLink('https://autokorr.ru/wp-content/uploads/2022/02/r3k_rules22.pdf') },
              { text: 'Закрыть', role: 'cancel' },
            ]}
          ></IonActionSheet>
        </IonItem>
        <IonItem
          detail
          href={isPlatform('ios') ? 'https://apps.apple.com/app/id425589565' : 'https://www.google.com/search?q=gps+tracker+app'}
          target="_blank"
        >
          <IonLabel>Приложение GPS-трекер</IonLabel>
        </IonItem>
      </IonList>

      <IonList inset>
        <Clock />
        <IonItem>
          <IonLabel id="whatItIs">GPS</IonLabel>
          {
            gpsState === 'unknown' ? <IonText slot="end" color="medium">Неизвестно</IonText> :
            gpsState === 'unsupported' ? <IonText slot="end" color="danger">Не поддерживается</IonText> :
            gpsState === 'userDenied' ? <IonText slot="end" color="danger">Не разрешено</IonText> :
            gpsState === 'error' ? <IonText slot="end" color="danger">Ошибка</IonText> :
            gpsState === 'ok' ? (data.gpsPosition ? (
              <IonText slot="end" color={data.gpsPosition.accuracy > 15 ? 'warning' : 'medium'}>
                Точность {Math.round(data.gpsPosition.accuracy)}м
              </IonText>
            ) : (
              <IonText slot="end" color="medium">Работает</IonText>
            )) :
            <IonText slot="end" color="medium">{gpsState}</IonText>
          }
        </IonItem>
        <IonItem>
          <IonLabel id="whatItIs">Экран, не гасни</IonLabel>
          {
            keepAwakeState === 'unsupported' ? <IonText slot="end" color="danger">Не поддерживается</IonText> :
            keepAwakeState === 'inactive' ? <IonText slot="end" color="warning">Не активировано</IonText> :
            keepAwakeState === 'active' ? <IonText slot="end" color="medium">Активировано</IonText> :
            <IonText slot="end" color="medium">{keepAwakeState}</IonText>
          }
        </IonItem>
      </IonList>

      <ListHeader inset>Приложение</ListHeader>
      <IonList inset>
        <IonItem button={canShare} detail={false} onClick={() => share({ url: window.location.origin })}>
          <IonLabel>Адрес</IonLabel>
          <IonText slot="end" color="medium" style={{ wordBreak: 'break-word', maxWidth: 'calc(100% - 5em)', textAlign: 'right' }}>
            {window.location.host}
          </IonText>
        </IonItem>
        <IonItem button={canShare} detail={false} onClick={() => share({ text: buildInfo.gitCommitHash })}>
          <IonLabel>Версия</IonLabel>
          <IonText slot="end" color="medium">{buildInfo.gitCommitHash.slice(0, 7)}</IonText>
        </IonItem>
        <IonItem>
          <IonLabel>Опубликована</IonLabel>
          <IonText slot="end" color="medium">{stringifyDateTime(new Date(buildInfo.date))}</IonText>
        </IonItem>
      </IonList>
    </IonContent>
  </>
})

const Clock = React.memo(function Clock() {
  const currentTime = useCurrentTime()

  return (
    <IonItem>
      <IonLabel>Часы</IonLabel>
      <IonText slot="end" color="medium" className="monospace-digits">
        {stringifyTime(currentTime * 60, true)}
      </IonText>
    </IonItem>
  )
})

function openLink(url: string) {
  window.open(url, '_blank', 'noopener,noreferrer')
}

const canShare = !!navigator.share

async function share(data: ShareData) {
  try {
    await navigator.share?.(data)
  } catch (error: unknown) {
    if (error instanceof Error && ['AbortError', 'InvalidStateError'].includes(error.name)) {
      return
    }
    throw error
  }
}
