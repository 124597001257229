import React from 'react'
import { IonActionSheet, IonItem, IonLabel, IonList, IonText } from '@ionic/react'
import { Data, useDatastore } from '../datastore'
import { calculateOdometers, changeOdometers, distancePrecision, getSign } from '../utils'
import ListHeader from './ListHeader'
import ListNote from './ListNote'
import NumberPrompt from './NumberPrompt'

interface Props extends Pick<Data, 'odometer' | 'odometerGpsOffset' | 'odometerCarOffset' | 'gpsOutdated'> {
  setData: ReturnType<typeof useDatastore>[1]
}

export default React.memo(function Odometer({ setData, ...props }: Props) {
  const odometers = calculateOdometers(props)
  const makeChangeHandler = (type: keyof typeof odometers) => (isAll: boolean, value: number) => setData({
    gpsOutdated: false,
    ...changeOdometers(props, type, value, isAll),
  })

  return <>
    <ListHeader inset>Расстояние от старта</ListHeader>
    <IonList inset>
      <OdometerInput
        title="По маршрутному листу"
        value={odometers.roadmap}
        bold
        onChange={makeChangeHandler('roadmap')}
      />
      <OdometerInput
        title="По GPS-трекеру"
        value={odometers.gps}
        onChange={makeChangeHandler('gps')}
      />
      <OdometerInput
        title="По автомобилю"
        value={odometers.car}
        onChange={makeChangeHandler('car')}
      />
    </IonList>
    {props.gpsOutdated && (
      <ListNote inset color="warning">
        Встроенный GPS не работал некоторое время. Синхронизируйте расстояния.
      </ListNote>
    )}
  </>
})

interface OdometerInputProps {
  title: string
  value: number
  bold?: boolean
  onChange(isAll: boolean, value: number): void
}

function OdometerInput({ title, value, bold, onChange }: OdometerInputProps) {
  const id = React.useId()
  const displayedValue = value.toFixed(distancePrecision)
  const fullTitle = `Расстояние ${title[0].toLocaleLowerCase()}${title.slice(1)}`
  const promptRef = React.useRef<HTMLIonAlertElement>(null)
  const [promptState, setPromptState] = React.useState({
    isChangingAll: false,
    initialValue: 0,
    currentValue: 0 as number | null,
  })
  const inputDiff = promptState.currentValue === null ? null : promptState.currentValue - Number(displayedValue)
  const readableInputDiff = inputDiff === null ? '–' : `${getSign(inputDiff)}${Math.abs(inputDiff).toFixed(distancePrecision)}`

  const openPrompt = (isChangingAll: boolean) => {
    setPromptState({
      isChangingAll,
      initialValue: Number(displayedValue),
      currentValue: Number(displayedValue),
    })
    promptRef.current?.present()
  }
  const handlePromptInput = (currentValue: number | null) => setPromptState(promptState => ({
    ...promptState,
    currentValue: currentValue,
  }))
  const handlePromptSubmit = (value: number) => onChange(promptState.isChangingAll, value)

  return (
    <IonItem detail button id={id}>
      <IonLabel>{title}</IonLabel>
      <IonText
        slot="end"
        color={bold ? 'dark' : 'medium'}
        className="monospace-digits"
        style={{ fontWeight: bold ? '600' : undefined }}
      >
        {displayedValue} км
      </IonText>
      <IonActionSheet
        trigger={id}
        header={fullTitle}
        buttons={[
          { text: 'Изменить все расстояния', handler: () => openPrompt(true) },
          { text: 'Изменить только это', handler: () => openPrompt(false) },
          { text: 'Отмена', role: 'cancel' },
        ]}
      />
      <NumberPrompt
        initialValue={promptState.initialValue}
        label={`${fullTitle} (км)`}
        hint={
          promptState.isChangingAll
            ? `ВСЕ расстояния будут изменены на ${readableInputDiff} км`
            : `ТОЛЬКО ЭТО расстояние будет изменено на ${readableInputDiff} км`
        }
        fractional
        onInput={handlePromptInput}
        onChange={handlePromptSubmit}
        ref={promptRef}
      />
    </IonItem>
  )
}
