import React from 'react'
import styles from './StatusBarUnderlay.module.css'
import { PredefinedColors } from '@ionic/core'

interface Props {
  color: PredefinedColors
}

/**
 * Подложка системную под панель статуса вверху, чтобы содержимое этой панели легко читалось, когда содержимое экрана
 * скроллится. Нужно применять, когда на экране не используется <IonHeader />, при этом <IonContent /> нужно добавить
 * класс `headerless-content`..
 */
export default React.memo(function StatusBarUnderlay({ color }: Props) {
  return <div className={`${styles.underlay} ion-color-${color}`} />
})
