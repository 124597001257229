import { useEffect, useMemo, useState } from 'react'
import EventDispatcher, { CancelWatcher, Watcher } from './eventDispatcher'
import { throttle } from './utils'

export interface RegularitySpeed {
  /** Расстояние от старта (последнего КВ) до точки смены скорости в километрах */
  distance: number
  /** Средняя скорость начиная от этой точки, в км/ч */
  speed: number
}

export interface GpsPosition {
  latitude: number
  longitude: number
  /** В метрах над уровнем моря; null значит неизвестно */
  altitude: number | null
  /** В метрах */
  accuracy: number
  /** В км/ч */
  speed: number | null
  /** Unix ms */
  timestamp: number
}

export interface Data {
  /** Допустимое опережение ВКВ в процентах */
  allowedOutrunning: number
  /** Дистанция дорожного сектора в километрах */
  distance: number
  /** Средняя скорость дорожного сектора, указанная в маршрутном листе, в км/ч */
  averageSpeed: number
  /** Норма времени на дорожный сектор в минутах */
  timeNorm: number
  /** Время начала дорожного сектора, hh:mm */
  startTime?: string
  /** Пройденные в дорожном секторе ДС и нейтрализация в минутах */
  pausePassed: number
  /** Пройденное расстояние в километрах */
  odometer: number
  /** Нужно прибавить к `odometer`, чтобы получить расстояние по GPS */
  odometerGpsOffset: number
  /** Нужно прибавить к `odometer`, чтобы получить расстояние по автомобилю */
  odometerCarOffset: number
  /** Время начала РД, hh:mm */
  regularityStartTime?: string
  /** Точки смены скорости в РД. Всегда упорядочены по возрастанию расстояния. Первая точка считается началом РД. */
  regularitySpeeds: RegularitySpeed[]
  /** Последние данные из GPS */
  gpsPosition: GpsPosition | null
  /** Не работал ли GPS достаточно времени, чтобы не доверять ему */
  gpsOutdated: boolean
}

type Update<T> = { readonly [K in keyof T]?: T[K] | undefined }

/**
 * Надёжное хранилище данных приложения с синхронизацией между вкладками
 */
class DataStore<T> {
  #storage: Storage
  #storageKey: string
  #latestDataVersion: number
  #defaultData: T
  #currentData: T
  #dataChangeDispatcher = new EventDispatcher<T>()
  #saveToStorage = throttle(1, () => this.#saveToStorageNow(this.#currentData))

  constructor(storage: Storage, storageKey: string, latestDataVersion: number, defaultData: T) {
    this.#storage = storage
    this.#storageKey = storageKey
    this.#latestDataVersion = latestDataVersion
    this.#defaultData = defaultData
    this.#currentData = this.#loadFromStorage()
    this.#watchStorage()
  }

  /**
   * Возвращает текущие данные приложения
   */
  public get(): T {
    return this.#currentData
  }

  /**
   * Изменяет текущие данные приложения. Сохраняет в надёжное хранилище.
   */
  public set(data: T): void {
    this.#currentData = data
    this.#dataChangeDispatcher.dispatch(this.#currentData)
    this.#saveToStorage()
  }

  /**
   * Сбрасывает все данные приложения до дефолтного состояния
   */
  public reset(): void {
    this.set(this.#defaultData)
  }

  /**
   * Изменяет текущие данные приложения, разрешая частичное изменение. Сохраняет в надёжное хранилище.
   */
  public change(update: Update<T>): void {
    this.set(applyDataUpdate(this.#currentData, update))
  }

  /**
   * Подписывается на изменения данных приложения
   */
  public onChange(watcher: Watcher<T>): CancelWatcher {
    return this.#dataChangeDispatcher.watch(watcher)
  }

  #watchStorage(): void {
    window.addEventListener('storage', event => {
      if (event.storageArea === this.#storage && event.key === this.#storageKey) {
        try {
          this.#currentData = event.newValue === null ? this.#defaultData : this.#parseStoredValue(event.newValue)
          this.#dataChangeDispatcher.dispatch(this.#currentData)
        } catch (error) {
          console.error(`Не удалось применить новые сохранённые данные из другой вкладки`, error)
        }
      }
    })
  }

  #parseStoredValue(value: string): T {
    const [version, data] = JSON.parse(value) as [number, T]
    if (version !== this.#latestDataVersion) {
      return this.#defaultData
    }
    return { ...this.#defaultData, ...data }
  }

  #loadFromStorage(): T {
    try {
      const storedValue = this.#storage.getItem(this.#storageKey)
      if (!storedValue) {
        return this.#defaultData
      }
      return this.#parseStoredValue(storedValue)
    } catch (error) {
      console.error(`Не удалось загрузить сохранённые данные`, error)
      return this.#defaultData
    }
  }

  #saveToStorageNow(data: T): void {
    if (data === this.#defaultData) {
      this.#storage.removeItem(this.#storageKey)
    } else {
      this.#storage.setItem(this.#storageKey, JSON.stringify([this.#latestDataVersion, this.#currentData]))
    }
  }
}

function applyDataUpdate<T>(data: Readonly<T>, update: Update<T>): Readonly<T> {
  const newData: T = { ...data }
  for (const [key, value] of Object.entries(update) as [keyof T, T[keyof T]][]) {
    if (value === undefined) {
      delete newData[key]
    } else {
      newData[key] = value as never
    }
  }
  return newData
}

const datastore = new DataStore<Readonly<Data>>(
  localStorage,
  'appData',
  1,
  {
    allowedOutrunning: 10,
    distance: 50,
    averageSpeed: 30,
    timeNorm: 110,
    pausePassed: 0,
    odometer: 0,
    odometerGpsOffset: 0,
    odometerCarOffset: 0,
    regularitySpeeds: [],
    gpsPosition: null,
    gpsOutdated: false,
  },
)

export default datastore

/**
 * Возвращает и сохраняет всё состояние приложения надёжно
 */
export function useDatastore() {
  const [data, setData] = useState(() => datastore.get())
  useEffect(() => datastore.onChange(setData), [setData])
  const changeData = useMemo(() => datastore.change.bind(datastore), [])
  return [data, changeData] as const
}
